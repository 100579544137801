// Auto-generated file - DO NOT EDIT MANUALLY

import pA1 from "./pA1.png";
import pA1D from "./pA1D.png";
import pA1P from "./pA1P.png";
import pA1S from "./pA1S.png";
import pA1T from "./pA1T.png";
import pA2 from "./pA2.png";
import pA2D from "./pA2D.png";
import pA2P from "./pA2P.png";
import pA2S from "./pA2S.png";
import pA2T from "./pA2T.png";
import pA3 from "./pA3.png";
import pA3D from "./pA3D.png";
import pA3P from "./pA3P.png";
import pA3S from "./pA3S.png";
import pA3T from "./pA3T.png";
import pA4 from "./pA4.png";
import pA4D from "./pA4D.png";
import pA4P from "./pA4P.png";
import pA4S from "./pA4S.png";
import pA4T from "./pA4T.png";
import pA5 from "./pA5.png";
import pA5D from "./pA5D.png";
import pA5P from "./pA5P.png";
import pA5S from "./pA5S.png";
import pA5T from "./pA5T.png";
import pA6 from "./pA6.png";
import pA6D from "./pA6D.png";
import pA6P from "./pA6P.png";
import pA6S from "./pA6S.png";
import pA6T from "./pA6T.png";
import pA7 from "./pA7.png";
import pA7D from "./pA7D.png";
import pA7P from "./pA7P.png";
import pA7S from "./pA7S.png";
import pA7T from "./pA7T.png";
import pA8 from "./pA8.png";
import pA8D from "./pA8D.png";
import pA8P from "./pA8P.png";
import pA8S from "./pA8S.png";
import pA8T from "./pA8T.png";
import pB1 from "./pB1.png";
import pB1D from "./pB1D.png";
import pB1P from "./pB1P.png";
import pB1S from "./pB1S.png";
import pB1T from "./pB1T.png";
import pB2 from "./pB2.png";
import pB2D from "./pB2D.png";
import pB2P from "./pB2P.png";
import pB2S from "./pB2S.png";
import pB2T from "./pB2T.png";
import pB3 from "./pB3.png";
import pB3D from "./pB3D.png";
import pB3P from "./pB3P.png";
import pB3S from "./pB3S.png";
import pB3T from "./pB3T.png";
import pB4 from "./pB4.png";
import pB4D from "./pB4D.png";
import pB4P from "./pB4P.png";
import pB4S from "./pB4S.png";
import pB5 from "./pB5.png";
import pB5D from "./pB5D.png";
import pB5P from "./pB5P.png";
import pB5S from "./pB5S.png";
import pB5T from "./pB5T.png";
import pB6 from "./pB6.png";
import pB6D from "./pB6D.png";
import pB6P from "./pB6P.png";
import pB6S from "./pB6S.png";
import pB6T from "./pB6T.png";
import pB7 from "./pB7.png";
import pB7D from "./pB7D.png";
import pB7P from "./pB7P.png";
import pB7S from "./pB7S.png";
import pB7T from "./pB7T.png";
import pB8 from "./pB8.png";
import pB8D from "./pB8D.png";
import pB8P from "./pB8P.png";
import pB8S from "./pB8S.png";
import pB8T from "./pB8T.png";
import pF1 from "./pF1.png";
import pF1D from "./pF1D.png";
import pF1P from "./pF1P.png";
import pF1S from "./pF1S.png";
import pF1T from "./pF1T.png";
import pF2 from "./pF2.png";
import pF2D from "./pF2D.png";
import pF2P from "./pF2P.png";
import pF2S from "./pF2S.png";
import pF2T from "./pF2T.png";
import pF3 from "./pF3.png";
import pF3D from "./pF3D.png";
import pF3P from "./pF3P.png";
import pF3S from "./pF3S.png";
import pF3T from "./pF3T.png";
import pF4 from "./pF4.png";
import pF4D from "./pF4D.png";
import pF4P from "./pF4P.png";
import pF4S from "./pF4S.png";
import pF4T from "./pF4T.png";
import pF5 from "./pF5.png";
import pF5D from "./pF5D.png";
import pF5P from "./pF5P.png";
import pF5S from "./pF5S.png";
import pF5T from "./pF5T.png";
import pF6 from "./pF6.png";
import pF6P from "./pF6P.png";
import pF6S from "./pF6S.png";
import pF6T from "./pF6T.png";
import pF7 from "./pF7.png";
import pF7D from "./pF7D.png";
import pF7P from "./pF7P.png";
import pF7S from "./pF7S.png";
import pF7T from "./pF7T.png";
import pF8 from "./pF8.png";
import pF8D from "./pF8D.png";
import pF8P from "./pF8P.png";
import pF8S from "./pF8S.png";
import pF8T from "./pF8T.png";
import pFD from "./pFD.png";
import pN1 from "./pN1.png";
import pN1D from "./pN1D.png";
import pN1P from "./pN1P.png";
import pN1S from "./pN1S.png";
import pN1T from "./pN1T.png";
import pN2 from "./pN2.png";
import pN2D from "./pN2D.png";
import pN2P from "./pN2P.png";
import pN2S from "./pN2S.png";
import pN2T from "./pN2T.png";
import pN3 from "./pN3.png";
import pN3D from "./pN3D.png";
import pN3P from "./pN3P.png";
import pN3S from "./pN3S.png";
import pN3T from "./pN3T.png";
import pN4 from "./pN4.png";
import pN4D from "./pN4D.png";
import pN4P from "./pN4P.png";
import pN4S from "./pN4S.png";
import pN4T from "./pN4T.png";
import pN5 from "./pN5.png";
import pN5D from "./pN5D.png";
import pN5P from "./pN5P.png";
import pN5S from "./pN5S.png";
import pN5T from "./pN5T.png";
import pN6 from "./pN6.png";
import pN6D from "./pN6D.png";
import pN6P from "./pN6P.png";
import pN6S from "./pN6S.png";
import pN6T from "./pN6T.png";
import pN7 from "./pN7.png";
import pN7D from "./pN7D.png";
import pN7P from "./pN7P.png";
import pN7S from "./pN7S.png";
import pN7T from "./pN7T.png";
import pN8 from "./pN8.png";
import pN8D from "./pN8D.png";
import pN8P from "./pN8P.png";
import pN8S from "./pN8S.png";
import pN8T from "./pN8T.png";
import pR1 from "./pR1.png";
import pR1D from "./pR1D.png";
import pR1P from "./pR1P.png";
import pR1S from "./pR1S.png";
import pR1T from "./pR1T.png";
import pR2 from "./pR2.png";
import pR2D from "./pR2D.png";
import pR2P from "./pR2P.png";
import pR2S from "./pR2S.png";
import pR2T from "./pR2T.png";
import pR3 from "./pR3.png";
import pR3D from "./pR3D.png";
import pR3P from "./pR3P.png";
import pR3S from "./pR3S.png";
import pR3T from "./pR3T.png";
import pR4 from "./pR4.png";
import pR4D from "./pR4D.png";
import pR4P from "./pR4P.png";
import pR4S from "./pR4S.png";
import pR4T from "./pR4T.png";
import pR5 from "./pR5.png";
import pR5D from "./pR5D.png";
import pR5P from "./pR5P.png";
import pR5S from "./pR5S.png";
import pR5T from "./pR5T.png";
import pR6 from "./pR6.png";
import pR6D from "./pR6D.png";
import pR6P from "./pR6P.png";
import pR6S from "./pR6S.png";
import pR6T from "./pR6T.png";
import pR7 from "./pR7.png";
import pR7D from "./pR7D.png";
import pR7P from "./pR7P.png";
import pR7S from "./pR7S.png";
import pR7T from "./pR7T.png";
import pR8 from "./pR8.png";
import pR8D from "./pR8D.png";
import pR8P from "./pR8P.png";
import pR8S from "./pR8S.png";
import pR8T from "./pR8T.png";
import pS1 from "./pS1.png";
import pS1D from "./pS1D.png";
import pS1P from "./pS1P.png";
import pS1S from "./pS1S.png";
import pS1T from "./pS1T.png";
import pS2 from "./pS2.png";
import pS2D from "./pS2D.png";
import pS2P from "./pS2P.png";
import pS2S from "./pS2S.png";
import pS2T from "./pS2T.png";
import pS3 from "./pS3.png";
import pS3D from "./pS3D.png";
import pS3P from "./pS3P.png";
import pS3S from "./pS3S.png";
import pS3T from "./pS3T.png";
import pS4 from "./pS4.png";
import pS4D from "./pS4D.png";
import pS4P from "./pS4P.png";
import pS4S from "./pS4S.png";
import pS4T from "./pS4T.png";
import pS5 from "./pS5.png";
import pS5D from "./pS5D.png";
import pS5P from "./pS5P.png";
import pS5S from "./pS5S.png";
import pS5T from "./pS5T.png";
import pS6 from "./pS6.png";
import pS6D from "./pS6D.png";
import pS6P from "./pS6P.png";
import pS6S from "./pS6S.png";
import pS6T from "./pS6T.png";
import pS7 from "./pS7.png";
import pS7D from "./pS7D.png";
import pS7P from "./pS7P.png";
import pS7S from "./pS7S.png";
import pS7T from "./pS7T.png";
import pS8 from "./pS8.png";
import pS8D from "./pS8D.png";
import pS8P from "./pS8P.png";
import pS8S from "./pS8S.png";
import pS8T from "./pS8T.png";
import pB4T from "./pB4T.png";
import pinned from "./pinned.png";
import pinnedD from "./pinnedD.png";
import pinnedP from "./pinnedP.png";
import pinnedS from "./pinnedS.png";
import pinnedT from "./pinnedT.png";

export const mapIcons = {
  pA1,
  pA1D,
  pA1P,
  pA1S,
  pA1T,
  pA2,
  pA2D,
  pA2P,
  pA2S,
  pA2T,
  pA3,
  pA3D,
  pA3P,
  pA3S,
  pA3T,
  pA4,
  pA4D,
  pA4P,
  pA4S,
  pA4T,
  pA5,
  pA5D,
  pA5P,
  pA5S,
  pA5T,
  pA6,
  pA6D,
  pA6P,
  pA6S,
  pA6T,
  pA7,
  pA7D,
  pA7P,
  pA7S,
  pA7T,
  pA8,
  pA8D,
  pA8P,
  pA8S,
  pA8T,
  pB1,
  pB1D,
  pB1P,
  pB1S,
  pB1T,
  pB2,
  pB2D,
  pB2P,
  pB2S,
  pB2T,
  pB3,
  pB3D,
  pB3P,
  pB3S,
  pB3T,
  pB4,
  pB4D,
  pB4P,
  pB4S,
  pB5,
  pB5D,
  pB5P,
  pB5S,
  pB5T,
  pB6,
  pB6D,
  pB6P,
  pB6S,
  pB6T,
  pB7,
  pB7D,
  pB7P,
  pB7S,
  pB7T,
  pB8,
  pB8D,
  pB8P,
  pB8S,
  pB8T,
  pF1,
  pF1D,
  pF1P,
  pF1S,
  pF1T,
  pF2,
  pF2D,
  pF2P,
  pF2S,
  pF2T,
  pF3,
  pF3D,
  pF3P,
  pF3S,
  pF3T,
  pF4,
  pF4D,
  pF4P,
  pF4S,
  pF4T,
  pF5,
  pF5D,
  pF5P,
  pF5S,
  pF5T,
  pF6,
  pF6P,
  pF6S,
  pF6T,
  pF7,
  pF7D,
  pF7P,
  pF7S,
  pF7T,
  pF8,
  pF8D,
  pF8P,
  pF8S,
  pF8T,
  pFD,
  pN1,
  pN1D,
  pN1P,
  pN1S,
  pN1T,
  pN2,
  pN2D,
  pN2P,
  pN2S,
  pN2T,
  pN3,
  pN3D,
  pN3P,
  pN3S,
  pN3T,
  pN4,
  pN4D,
  pN4P,
  pN4S,
  pN4T,
  pN5,
  pN5D,
  pN5P,
  pN5S,
  pN5T,
  pN6,
  pN6D,
  pN6P,
  pN6S,
  pN6T,
  pN7,
  pN7D,
  pN7P,
  pN7S,
  pN7T,
  pN8,
  pN8D,
  pN8P,
  pN8S,
  pN8T,
  pR1,
  pR1D,
  pR1P,
  pR1S,
  pR1T,
  pR2,
  pR2D,
  pR2P,
  pR2S,
  pR2T,
  pR3,
  pR3D,
  pR3P,
  pR3S,
  pR3T,
  pR4,
  pR4D,
  pR4P,
  pR4S,
  pR4T,
  pR5,
  pR5D,
  pR5P,
  pR5S,
  pR5T,
  pR6,
  pR6D,
  pR6P,
  pR6S,
  pR6T,
  pR7,
  pR7D,
  pR7P,
  pR7S,
  pR7T,
  pR8,
  pR8D,
  pR8P,
  pR8S,
  pR8T,
  pS1,
  pS1D,
  pS1P,
  pS1S,
  pS1T,
  pS2,
  pS2D,
  pS2P,
  pS2S,
  pS2T,
  pS3,
  pS3D,
  pS3P,
  pS3S,
  pS3T,
  pS4,
  pS4D,
  pS4P,
  pS4S,
  pS4T,
  pS5,
  pS5D,
  pS5P,
  pS5S,
  pS5T,
  pS6,
  pS6D,
  pS6P,
  pS6S,
  pS6T,
  pS7,
  pS7D,
  pS7P,
  pS7S,
  pS7T,
  pS8,
  pS8D,
  pS8P,
  pS8S,
  pS8T,
  pB4T,
  pinned,
  pinnedD,
  pinnedP,
  pinnedS,
  pinnedT,
};
