import styles from "./MapFilterBar.module.css";
import { useQuery, useSelector, useSettings } from "hooks";
import { useCheckboxFilter } from "../hooks/useCheckboxFilter";
import awaitingNormal0 from "assets/images/mapMarkers/awaiting/awaiting0.svg";
import awaitingNormal1 from "assets/images/mapMarkers/awaiting/awaiting1.svg";
import awaitingNormal2 from "assets/images/mapMarkers/awaiting/awaiting2.svg";
import awaitingNormal3 from "assets/images/mapMarkers/awaiting/awaiting3.svg";
import awaitingNormal4 from "assets/images/mapMarkers/awaiting/awaiting4.svg";
import awaitingNormal5 from "assets/images/mapMarkers/awaiting/awaiting5.svg";
import awaitingNormal6 from "assets/images/mapMarkers/awaiting/awaiting6.svg";
import awaitingNormal7 from "assets/images/mapMarkers/awaiting/awaiting7.svg";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { warehouseConstants } from "constants/warehouse";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiMontage } from "components/miloDesignSystem/atoms/icons/MdiMontage";
import { MdiWithBringing } from "components/miloDesignSystem/atoms/icons/MdiWithBringing";
import { MdiWithoutMontage } from "components/miloDesignSystem/atoms/icons/MdiWithoutMontage";
import { MdiWithoutBringing } from "components/miloDesignSystem/atoms/icons/MdiWithoutBringing";
import { IconRendererIcon } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { MdiColor } from "components/miloDesignSystem/atoms/icons/MdiColor";
import { MdiRound } from "components/miloDesignSystem/atoms/icons/MdiRound";
import { OrderTypeChoices } from "api/orders/enums";
import { MdiClaim } from "components/miloDesignSystem/atoms/icons/MdiClaim";
import { MdiScull } from "components/miloDesignSystem/atoms/icons/MdiScul";
import { MdiFlame } from "components/miloDesignSystem/atoms/icons/MdiFlame";
import { InputQuery } from "components/utils/queryProvider";
import { useRouteViewState } from "../routeCreatorState";

export const MapFilterBar = () => {
  const { updateQuery, query } = useQuery();
  const { isChecked, updateCheckbox } = useCheckboxFilter("timeGroup");
  const transport = useSettings().transport;
  const actions = useRouteViewState("slave", state => state.actions);

  const customUpdateQuery = (args: InputQuery) => {
    actions.openLoader("Trwa wczytywanie zamówień");
    updateQuery(args);
  };

  return (
    <div className={styles.rightBar}>
      <Paper className="p-3">
        <Typography fontSize="14" fontWeight="600" className="mb-3">
          Oznaczenia na mapie
        </Typography>
        <WarehouseFilters />
        {/* <ProductTypeFilters /> */}

        <PriorityFilters />
        <div className="mb-3">
          <Typography fontSize="10" fontWeight="700" className="mb-1">
            USŁUGI DODATKOWE
          </Typography>
          <div className="d-flex align-items-center gap-2">
            <LocationTag
              icon={MdiMontage}
              isChecked={query.isAssemblingRequested === "true"}
              onClick={() => {
                query.isAssemblingRequested === "true"
                  ? customUpdateQuery({ isAssemblingRequested: "" })
                  : customUpdateQuery({ isAssemblingRequested: "true" });
              }}
            />
            <LocationTag
              icon={MdiWithoutMontage}
              isChecked={query.assemblyNotRequested === "true"}
              onClick={() => {
                query.assemblyNotRequested === "true"
                  ? customUpdateQuery({ assemblyNotRequested: "" })
                  : customUpdateQuery({ assemblyNotRequested: "true" });
              }}
            />
            <LocationTag
              icon={MdiWithBringing}
              isChecked={query.hasCarrying === "true"}
              onClick={() => {
                query.hasCarrying === "true"
                  ? customUpdateQuery({ hasCarrying: "" })
                  : customUpdateQuery({ hasCarrying: "true" });
              }}
            />
            <LocationTag
              icon={MdiWithoutBringing}
              isChecked={query.withoutCarrying === "true"}
              onClick={() => {
                query.withoutCarrying === "true"
                  ? customUpdateQuery({ withoutCarrying: "" })
                  : customUpdateQuery({ withoutCarrying: "true" });
              }}
            />
          </div>
        </div>
        <div className="mb-3">
          <Typography fontSize="10" fontWeight="700" className="mb-1">
            INNE
          </Typography>
          <div className="d-flex align-items-center gap-2">
            <LocationTag
              icon={MdiRound}
              isChecked={query.type === OrderTypeChoices.STANDARD}
              onClick={() => {
                query.type === OrderTypeChoices.STANDARD
                  ? customUpdateQuery({ type: "" })
                  : customUpdateQuery({ type: OrderTypeChoices.STANDARD });
              }}
            />
            <LocationTag
              icon={MdiClaim}
              isChecked={query.type === OrderTypeChoices.COMPLAINT}
              onClick={() => {
                query.type === OrderTypeChoices.COMPLAINT
                  ? customUpdateQuery({ type: "" })
                  : customUpdateQuery({ type: OrderTypeChoices.COMPLAINT });
              }}
            />
          </div>
        </div>
        <div className="mb-3">
          <Typography fontSize="10" fontWeight="700">
            KOLOR OZNACZEŃ
          </Typography>
          <Typography fontSize="10" fontWeight="600" color="neutralBlack48" className="italic mb-1">
            (czas od złożenia zamówienia)
          </Typography>
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <LocationTag
              icon={<MdiColor color="neutralWhite100" size="14" />}
              isChecked={isChecked("1")}
              onClick={() => updateCheckbox(!isChecked("1"), "1")}
            />
            <LocationTag
              icon={<MdiColor color="success200" size="14" />}
              isChecked={isChecked("2")}
              onClick={() => updateCheckbox(!isChecked("2"), "2")}
            />
            <LocationTag
              icon={<MdiColor color="turquoise200" size="14" />}
              isChecked={isChecked("3")}
              onClick={() => updateCheckbox(!isChecked("3"), "3")}
            />
            <LocationTag
              icon={<MdiColor color="yellow100" size="14" />}
              isChecked={isChecked("4")}
              onClick={() => updateCheckbox(!isChecked("4"), "4")}
            />
            <LocationTag
              icon={<MdiColor color="orange200" size="14" />}
              isChecked={isChecked("5")}
              onClick={() => updateCheckbox(!isChecked("5"), "5")}
            />
            <LocationTag
              icon={<MdiColor color="deepOrange100" size="14" />}
              isChecked={isChecked("6")}
              onClick={() => updateCheckbox(!isChecked("6"), "6")}
            />
            <LocationTag
              icon={<MdiColor color="red200" size="14" />}
              isChecked={isChecked("7")}
              onClick={() => updateCheckbox(!isChecked("7"), "7")}
            />
          </div>
        </div>
        {transport.showOnMapOrdersOnWayToWarehouse && (
          <div className="mb-3">
            <Typography fontSize="10" fontWeight="700" className="mb-1">
              OCZEKUJE NA DOSTARCZENIE DO MAGAZYNU
            </Typography>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <LocationTag
                icon={awaitingNormal0}
                isChecked={isChecked("8")}
                onClick={() => updateCheckbox(!isChecked("8"), "8")}
              />
              <LocationTag
                icon={awaitingNormal1}
                isChecked={isChecked("9")}
                onClick={() => updateCheckbox(!isChecked("9"), "9")}
              />
              <LocationTag
                icon={awaitingNormal2}
                isChecked={isChecked("10")}
                onClick={() => updateCheckbox(!isChecked("10"), "10")}
              />
              <LocationTag
                icon={awaitingNormal3}
                isChecked={isChecked("11")}
                onClick={() => updateCheckbox(!isChecked("11"), "11")}
              />
              <LocationTag
                icon={awaitingNormal4}
                isChecked={isChecked("12")}
                onClick={() => updateCheckbox(!isChecked("12"), "12")}
              />
              <LocationTag
                icon={awaitingNormal5}
                isChecked={isChecked("13")}
                onClick={() => updateCheckbox(!isChecked("13"), "13")}
              />
              <LocationTag
                icon={awaitingNormal6}
                isChecked={isChecked("14")}
                onClick={() => updateCheckbox(!isChecked("14"), "14")}
              />
              <LocationTag
                icon={awaitingNormal7}
                isChecked={isChecked("15")}
                onClick={() => updateCheckbox(!isChecked("15"), "15")}
              />
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

const WarehouseFilters = () => {
  const { updateQuery, query } = useQuery();
  const warehouses = useSelector(state => state.partials.warehouses);
  const actions = useRouteViewState("slave", state => state.actions);

  if (warehouses.length === 1) return null;
  return (
    <div className="mb-3">
      <Typography fontSize="10" fontWeight="700" className="mb-1">
        MAGAZYNY
      </Typography>
      <div className="d-flex align-items-center gap-2">
        {warehouses.map(warehouse => {
          const isChecked = query.locatedInWarehouse === String(warehouse.id);
          return (
            <LocationTag
              key={warehouse.id}
              icon={warehouseConstants.getWarehouseIcons(warehouse.icon)}
              isChecked={isChecked}
              onClick={() => {
                actions.openLoader("Trwa wczytywanie zamówień");
                if (isChecked) {
                  updateQuery({ locatedInWarehouse: "" });
                } else {
                  updateQuery({ locatedInWarehouse: String(warehouse.id) });
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
// const ProductTypeFilters = () => {
//   const { isChecked, updateCheckbox } = useCheckboxFilter("productsKind");
//   return (
//     <div className="mb-3">
//       <Typography fontSize="10" fontWeight="700" className="mb-1">
//         TYP MEBLI
//       </Typography>
//       <div className="d-flex align-items-center gap-2">
//         <LocationTag
//           title="skrzyniowe"
//           icon={MdiSquare}
//           isChecked={isChecked("upholstery")}
//           onClick={() => updateCheckbox(!isChecked("upholstery"), "upholstery")}
//         />
//         <LocationTag
//           title="tapicerowane"
//           icon={MdiTexture}
//           isChecked={isChecked("box")}
//           onClick={() => updateCheckbox(!isChecked("box"), "box")}
//         />
//         {/* <LocationTag
//           title="inne / mieszane"
//           icon={mapIcons.pA1S}
//           isChecked={isChecked("other")}
//           onClick={() => updateCheckbox(!isChecked("other"), "other")}
//         /> */}
//       </div>
//     </div>
//   );
// };

const PriorityFilters = () => {
  const { isChecked, updateCheckbox } = useCheckboxFilter("priority");
  return (
    <div className="mb-3">
      <Typography fontSize="10" fontWeight="700" className="mb-1">
        PRIORYTET
      </Typography>
      <div className="d-flex align-items-center gap-2">
        <LocationTag
          icon={MdiRound}
          isChecked={isChecked("NORMAL")}
          onClick={() => updateCheckbox(!isChecked("NORMAL"), "NORMAL")}
        />

        <LocationTag
          icon={MdiFlame}
          isChecked={isChecked("HIGH")}
          onClick={() => updateCheckbox(!isChecked("HIGH"), "HIGH")}
        />

        <LocationTag
          icon={MdiScull}
          isChecked={isChecked("CRITICAL")}
          onClick={() => updateCheckbox(!isChecked("CRITICAL"), "CRITICAL")}
        />
      </div>
    </div>
  );
};

const LocationTag = ({
  icon,
  title,
  isChecked,
  onClick,
}: {
  icon: IconRendererIcon;
  title?: string;
  isChecked: boolean;
  onClick: () => void;
}) => {
  if (title) {
    return (
      <Tooltip title={title}>
        <div onClick={onClick} className={styles.tag}>
          <Tag
            label=""
            startIcon={icon}
            type={isChecked ? "filled" : "outlined"}
            variant={isChecked ? "deepPurple50" : "quaternary"}
          />
        </div>
      </Tooltip>
    );
  }
  return (
    <div onClick={onClick} className={styles.tag}>
      <Tag
        label=""
        startIcon={icon}
        type={isChecked ? "filled" : "outlined"}
        variant={isChecked ? "deepPurple50" : "quaternary"}
      />
    </div>
  );
};
